import { template as template_bc1647274c14458e9b5a7b1e987d6bf3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bc1647274c14458e9b5a7b1e987d6bf3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
