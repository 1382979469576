import { template as template_d10eb442ae854b03abf0f45c0c07b76d } from "@ember/template-compiler";
const FKText = template_d10eb442ae854b03abf0f45c0c07b76d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
